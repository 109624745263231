"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddonId = exports.AddonName = void 0;
var AddonName;
(function (AddonName) {
    AddonName["WhiteLabel"] = "White-label";
    AddonName["CPQ"] = "Configure price quote";
})(AddonName || (exports.AddonName = AddonName = {}));
var AddonId;
(function (AddonId) {
    AddonId["WhiteLabel"] = "white_label";
    AddonId["ConfigurePriceQuote"] = "configure_price_quote";
})(AddonId || (exports.AddonId = AddonId = {}));
