"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActivityLogType = void 0;
var ActivityLogType;
(function (ActivityLogType) {
    ActivityLogType["PublishCustomizerProduct"] = "publishCustomizerProduct";
    ActivityLogType["SelectTheme"] = "selectTheme";
    ActivityLogType["PublishTheme"] = "publishTheme";
    ActivityLogType["AddStartingPointToStore"] = "addStartingPointToStore";
    ActivityLogType["CompletedNewBuilderOnBoarding2022"] = "completedNewBuilderOnBoarding2022";
    ActivityLogType["CompleteOnboarding"] = "completeOnboarding";
    ActivityLogType["CreateProduct"] = "createProduct";
    ActivityLogType["DuplicateProduct"] = "duplicateProduct";
    ActivityLogType["UseDemoAsTemplate"] = "useDemoAsTemplate";
    ActivityLogType["UploadAsset"] = "uploadAsset";
    ActivityLogType["CreateQuestion"] = "createQuestion";
    ActivityLogType["CreateAnswer"] = "createAnswer";
    ActivityLogType["FirstBuilderVisit"] = "firstBuilderVisit";
    ActivityLogType["RegisterSurvey"] = "registerSurvey";
    ActivityLogType["EditAppEmbed"] = "editAppEmbed";
})(ActivityLogType || (exports.ActivityLogType = ActivityLogType = {}));
