"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiKeyScope = exports.IntegrationScope = exports.AdminUserScope = exports.UserScope = void 0;
var UserScope;
(function (UserScope) {
    UserScope["Admin"] = "admin";
    UserScope["Resubscribe"] = "resubscribe";
})(UserScope || (exports.UserScope = UserScope = {}));
var AdminUserScope;
(function (AdminUserScope) {
    AdminUserScope["Master"] = "master";
    AdminUserScope["MczrAdmin"] = "mczr-admin";
})(AdminUserScope || (exports.AdminUserScope = AdminUserScope = {}));
var IntegrationScope;
(function (IntegrationScope) {
    IntegrationScope["Ecommerce"] = "ecommerce";
    IntegrationScope["EcommerceConnector"] = "ecommerce-connector";
    IntegrationScope["JobRunner"] = "job-runner";
})(IntegrationScope || (exports.IntegrationScope = IntegrationScope = {}));
var ApiKeyScope;
(function (ApiKeyScope) {
    ApiKeyScope["Admin"] = "admin";
    ApiKeyScope["Read"] = "read";
    ApiKeyScope["OrdersRead"] = "orders:read";
    ApiKeyScope["DesignsRead"] = "designs:read";
    ApiKeyScope["InventoryRead"] = "inventory:read";
})(ApiKeyScope || (exports.ApiKeyScope = ApiKeyScope = {}));
